.sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: #22252c;
}

.sidebar ul {
  background-color: #22252c !important;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ml-200 {
  margin-left: 200px;
}

.ml-250 {
  margin-left: 250px;
}

.ml-80 {
  margin-left: 80px;
}
.site-content-layout {
  margin: 20px 16px;
  padding: 24px;
  padding-top: 0;
  min-height: 100vh;
}

@media (max-width: 900px) {
  .site-content-layout {
    margin: 10px;
    padding: 5px 0px;
  }
}
