@import "~antd/dist/antd.less";

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.ant-card-bordered {
  border: 1.5px solid #ccc;
}

.anticon {
  vertical-align: 0;
}

@media (max-width: 767px) {
  .ant-card-body {
    padding: 5px;
  }
}

.img-fluid {
  width: 100%;
}

.text-center {
  text-align: center;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@primary-color: #D31145;@font-size-base: 14px;@theme: dark;@border-color-base: #ccc;@divider-color: #ccc;