:global {
  .ant-table thead > tr > th {
    color: #fff;
    background-color: #E5355B ;
  }
  .ant-table thead > tr > th,
  .ant-table tbody > tr > td {
    padding: 13px;
  }
  .ant-table-body{
    overflow: auto !important;
  }
}
.rowLight {
  background-color: #fff;
}
.rowLightPink {
  background-color: #f5cecc;
}

@primary-color: #D31145;@font-size-base: 14px;@theme: dark;@border-color-base: #ccc;@divider-color: #ccc;